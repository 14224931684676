








































































































import { Component, Vue } from "vue-property-decorator";
import Header from "../components/Header.vue";

@Component({
  components: {
    Header,
  },
})
export default class Home extends Vue {
  private get isLoading(): boolean {
    return this.$store.state.isLoading;
  }
}
