





























import { Component, Vue } from "vue-property-decorator";
import SigninDialog from "./SigninDialog.vue";
import SignupDialog from "./SignupDialog.vue";
import { SessionStorageAdapter } from "@/adapters/SessionStorageAdapter";
@Component({
  components: { SigninDialog, SignupDialog },
})
export default class Header extends Vue {
  private signinVisible = false;

  private signupVisible = false;

  private onClickSignin() {
    this.signinVisible = true;
  }

  private onClickSignup() {
    this.signupVisible = true;
  }

  private onClickAlbum() {
    this.$router.push("/album");
  }

  private get isLogin(): boolean {
    return SessionStorageAdapter.isLogin();
  }

  private get isLoading(): boolean {
    return this.$store.state.isLoading;
  }
}
