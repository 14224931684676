




















import { Component, PropSync, Prop, Vue } from "vue-property-decorator";

@Component
export default class CommonDialog extends Vue {
  @Prop({ type: String, required: true })
  private title!: string;

  @PropSync("dialogVisibleProp", { type: Boolean, required: true })
  private dialogVisible!: boolean;

  @Prop({ type: Function, required: true })
  private okCb!: () => void;

  @Prop({ type: Function, required: true })
  private cancelCb!: () => void;

  @Prop({ type: String, required: false, default: "500" })
  private dialogWidth!: string;
}
